<template>
  <div class="content">
    <base-header class="pb-7">
      <div class="row align-items-center py-4 d-md-none">
        <div class="col-lg-6 col-7">
          <h6 class="h2 text-white d-inline-block mb-0">单位信息审核</h6>
          <nav aria-label="breadcrumb" class="d-none d-md-inline-block ml-md-4">
            <route-bread-crumb></route-bread-crumb>
          </nav>
        </div>
      </div>
    </base-header>
    <div class="container-fluid mt--7">
      <div>
        <card refs="l1" class="no-border-card" body-classes="pb-1" footer-classes="pb-2">
          <div class="row mb-3">
            <div class="col d-md-flex">
              <div class="mr-md-1 my-1 d-flex">
                <el-input class="search" v-model="filters.search" placeholder="输入关键字检索" prefix-icon="el-icon-search" clearable></el-input>
              </div>
              <div class="mx-md-1 my-1">
                <el-button type="success" @click="fetchData" :loading="loading" class="fw">{{ this.loading ? "加载中..." : "检索" }}</el-button>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col">
              <el-table header-row-class-name="thead-light" style="width: 100%" v-loading="loading"
                :data="tableData" stripe>
                <el-table-column type="expand" width="45">
                  <template slot-scope="{row}">
                      <div class="pl-5">
                        <div>
                          <h6>企业性质</h6>
                          <span>{{ row.org_type }}</span>
                        </div>
                        <div v-if="row.org_district" class="mt-3">
                          <h6>所属地区</h6>
                          <span>{{ row.org_district }}</span>
                        </div>
                        <div class="mt-3">
                          <h6>国企单位</h6>
                          <span>{{ row.org_name }}</span>
                        </div>
                      </div>
                  </template>
                </el-table-column>
                <el-table-column prop="company_name" label="公司名称" min-width="200"></el-table-column>
                <el-table-column prop="created_at" label="创建时间" min-width="160">
                  <template slot-scope="{row}">
                    {{ toDateTimeString(row.created_at) }}
                  </template>
                </el-table-column>
                <el-table-column prop="status" sortable="true" label="状态" width="140px">
                    <template slot-scope="scope">
                        <span v-html="orgStatusName(scope.row.status)"></span>
                    </template>
                </el-table-column>
                <el-table-column width="160px" align="right" label="" class-name="pr-2">
                  <template slot-scope="{row}" class="d-flex">
                    <el-button size="mini" @click="audit(row)">审核</el-button>
                  </template>
                </el-table-column>
              </el-table>
            </div>
          </div>
          <div slot="footer" class="col-12 px-0 d-flex justify-content-sm-between flex-wrap">
            <el-pagination class="pb-3 mx--3" background layout="sizes, prev, pager, next"
              :page-sizes="pageSizeOptions" :page-size="filters.limit"
              :total="total" :current-page="filters.page"
              @size-change="pageSizeChange" @current-change="pageIndexChange" @prev-click="movePrev" @next-click="moveNext">
            </el-pagination>
          </div>
        </card>
      </div>
    </div>
    <modal :show.sync="auditFormVisible" @close="modelClosed" :can-close="!saving" modal-classes="modal-dialog-centered" body-classes="pt-0">
      <div slot="header">
        <span class="text-success">单位注册信息审核</span>
      </div>
      <audit-form v-if="!!updatingRow" :item-id="updatingRow.id" @cancelled="closeModal" @saved="savedStatus" :saving.sync="saving"></audit-form>
    </modal>
  </div>
</template>
<script>
import RouteBreadCrumb from "@/components/Breadcrumb/RouteBreadcrumb";

export default {
  components: {
    RouteBreadCrumb,
    'audit-form': () => import('./Audit')
  },
  data() {
    return {
      loading: false,
      filters: { search: '', status: 0, limit: 10, page: 1 },
      tableData: [],
      pageSizeOptions: [10, 20, 50, 100],
      total: 0,
      orgStatusOptions: [{ text: '待审核', value: 0 }, { text: '审核通过', value: 1 }, { text: '审核不通过', value: 2 }],
      auditFormVisible: false,
      updatingRow: null,
      saving: false,
      saved: false
    }
  },
  methods: {
    orgStatusName(status) {
        let code = this.orgStatusOptions.find((item)=>{ return item.value == status })
        if(code) {
            if(status === 2) return `<span class="text-danger">${code.text}</span>`
            return code.text
        }
        return status
    },
    fetchData() {
      this.loading = true;
      this.axios.get("orgs", { params: { ...this.filters } }).then((res) => {
        if (res.data.errcode == 0) {
            this.tableData = res.data.data.data.map(item => { item.updating=false; return item; })
            this.total = res.data.data.total
            this.$store.commit('stats/setTotalOrgs', this.total || 0)
            this.filters.page = res.data.data.page;
            this.loading = false;
        } else if (res.data.errcode == 60403) {
            setTimeout(this.fetchData, 1000)
        }
        else {
            this.$notifyErr('未能成功加载数据', res.data.errmsg)
        }
      })
      .catch((e) => {
        this.$notifyErr('未能成功加载数据', e.message)
        this.loading = false
      })
    },
    pageSizeChange(v) { this.filters.limit = v; this.fetchData() },
    pageIndexChange(v) { this.filters.page = v; this.fetchData() },
    movePrev() { this.filters.page --; this.fetchData() },
    moveNext() { this.filters.page ++; this.fetchData() },
    audit(row) {
        this.updatingRow = row
        this.auditFormVisible = true
        this.saved = false
    },
    savedStatus () {
      this.saved = true
      this.auditFormVisible = false
      this.modelClosed()
    },
    closeModal () {
      this.auditFormVisible = false
      this.modelClosed()
    },
    modelClosed () {
      if (this.saved) this.fetchData()
      this.updatingRow = null
    }
  },
  mounted () {
    this.fetchData()
  }
}
</script>