<template>
  <div class="content">
    <base-header class="pb-7">
      <div class="row align-items-center py-4 d-md-none">
        <div class="col-lg-6 col-7">
          <h6 class="h2 text-white d-inline-block mb-0">统计表审核</h6>
          <nav aria-label="breadcrumb" class="d-none d-md-inline-block ml-md-4">
            <route-bread-crumb></route-bread-crumb>
          </nav>
        </div>
      </div>
    </base-header>
    <div class="container-fluid mt--7">
      <div>
        <card refs="l1" class="no-border-card" body-classes="pb-1" footer-classes="pb-2">
            <div class="row mb-3">
                <div class="col d-md-flex">
                    <div class="d-md-flex flex-grow-1">
                    </div>
                    <div>
                        <div class="my-1 text-center">
                            <el-button type="success" @click="fetchData()" plain><i class="el-icon-refresh"></i> 刷新</el-button>
                        </div>
                    </div>
                </div>
            </div>
          <div class="row">
            <div class="col">
               <el-tabs type="card" v-model="filters.statusGroup">
                <el-tab-pane label="待审核" name="0"></el-tab-pane>
                <el-tab-pane label="已审核" name="1"></el-tab-pane>
              </el-tabs>
              <el-table header-row-class-name="thead-light" style="width: 100%" v-loading="loading"
                :data="tableData" stripe>
                <el-table-column prop="company_name" label="公司名称" min-width="180"></el-table-column>
                <el-table-column prop="year" label="年度" min-width="80"></el-table-column>
                <el-table-column prop="start_date" label="起始日期" min-width="120">
                    <template slot-scope="{row}">
                        <span v-html="toDateString(row.start_date)"></span>
                    </template>
                </el-table-column>
                <el-table-column prop="end_date" label="截止日期" min-width="120">
                    <template slot-scope="{row}">
                        <span v-html="toDateString(row.end_date)"></span>
                    </template>
                </el-table-column>
                <el-table-column prop="created_at" label="创建时间" min-width="170">
                  <template slot-scope="{row}">
                    {{ toDateTimeString(row.created_at) }}
                  </template>
                </el-table-column>
                <el-table-column prop="status" sortable="true" label="状态" width="120px">
                    <template slot-scope="scope">
                        <span v-html="statusName(scope.row.status)"></span>
                    </template>
                </el-table-column>
                <el-table-column width="140px" align="right" label="" class-name="pr-2">
                  <template slot-scope="{row}" class="d-flex">
                    <a href="javascript:;" class="border-bottom mx-1" @click="open(row)" v-if="filters.statusGroup === '1'"> 查看</a>
                    <el-button size="mini" @click="audit(row)" v-else>审核</el-button>
                    <a href="javascript:;" class="border-bottom mx-1" @click="logs(row)"> 日志</a>
                  </template>
                </el-table-column>
              </el-table>
            </div>
          </div>
          <div slot="footer" class="col-12 px-0 d-flex justify-content-sm-between flex-wrap">
            <el-pagination class="pb-3 mx--3" background layout="sizes, prev, pager, next"
              :page-sizes="pageSizeOptions" :page-size="filters.limit"
              :total="total" :current-page="filters.page"
              @size-change="pageSizeChange" @current-change="pageIndexChange" @prev-click="movePrev" @next-click="moveNext">
            </el-pagination>
          </div>
        </card>
      </div>
    </div>
    <el-drawer :visible="!!updatingRow" :append-to-body="true" :modal-append-to-body="true" :before-close="beforeCloseAudit"
      direction="ltr" size="70%"
      title="统计表审核">
        <div class="px-4">
          <audit-form v-if="updatingRow" :item-id="updatingRow.id" @cancelled="closeModal" @saved="savedStatus" :saving.sync="saving"></audit-form>
        </div>
    </el-drawer>
    <el-drawer :visible="!!logsObject" :append-to-body="true" :modal-append-to-body="true" :before-close="beforeCloseLogs"
      direction="ltr" size="70%"
      title="数据修改日志">
        <div class="px-4">
          <logs v-if="logsObject" :object-id="logsObject.id" object-name="data_inputs"></logs>
        </div>
    </el-drawer>
  </div>
</template>
<script>
import RouteBreadCrumb from "@/components/Breadcrumb/RouteBreadcrumb";

export default {
  components: {
    RouteBreadCrumb,
    'audit-form': () => import('./Audit'),
    'logs': () => import('../Widgets/AuditLogs.vue')
  },
  data() {
    return {
      loading: false,
      filters: { statusGroup: '0', limit: 10, page: 1 },
      tableData: [],
      pageSizeOptions: [10, 20, 50, 100],
      total: 0,
      statusOptions: [{ text: '待审核', value: 0 }, { text: '审核通过', value: 1 }, { text: '审核不通过', value: 2 }, { text: '申请退回', value: 3 }],
      updatingRow: null,
      saving: false,
      saved: false,
      logsObject: null
    }
  },
  watch: {
    'filters.statusGroup' () {
      this.filters.page = 1
      this.fetchData()
    }
  },
  methods: {
    statusName(status) {
        let code = this.statusOptions.find((item)=>{ return item.value == status })
        if(code) {
            if(status === 2) return `<span class="text-danger">${code.text}</span>`
            return code.text
        }
        return status
    },
    fetchData() {
      this.loading = true;
      this.axios.get("dataauditionlist", { params: { ...this.filters } }).then((res) => {
        if (res.data.errcode == 0) {
            this.tableData = res.data.data.data
            this.total = res.data.data.total
            if (this.filters.statusGroup === '0') this.$store.commit('stats/setTotalData', this.total || 0)
            this.filters.page = res.data.data.page
            this.loading = false;
        } else if (res.data.errcode == 60403) {
            setTimeout(this.fetchData, 1000)
        }
        else {
            this.$notifyErr('未能成功加载数据', res.data.errmsg)
        }
      })
      .catch((e) => {
        this.$notifyErr('未能成功加载数据', e.message)
        this.loading = false
      })
    },
    pageSizeChange(v) { this.filters.limit = v; this.fetchData() },
    pageIndexChange(v) { this.filters.page = v; this.fetchData() },
    movePrev() { this.filters.page --; this.fetchData() },
    moveNext() { this.filters.page ++; this.fetchData() },
    open (row) {
        // this.$router.push('/datainputs/' + row.id)
        this.updatingRow = row
        this.saved = false
    },
    audit(row) {
        this.updatingRow = row
        this.saved = false
    },
    savedStatus () {
      this.saved = true
      this.modelClosed()
    },
    closeModal () {
      this.modelClosed()
    },
    modelClosed () {
      if (this.saved) this.fetchData()
      this.updatingRow = null
    },
    beforeCloseAudit (done) {
        this.updatingRow = null
        done()
    },
    logs (row) {
        this.logsObject = row
    },
    beforeCloseLogs () {
        this.logsObject = null
    }
  },
  mounted () {
    this.fetchData()
  }
}
</script>